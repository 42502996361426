<template>
    <div class="container">
        <manage-header />
        <div class="main">
            <el-container direction="horizontal">
                <el-aside width="220px" style="overflow: hidden">
                    <div class="menu scrollbar">
                        <div class="allmenuInfo">
                            <div
                                class="menu-box"
                                v-for="(item, index) in menus"
                                :key="index"
                                v-if="
                                    (!item.isVendor || item.isVendor === isVendor) &&
                                    (!item.isVip || item.isVip === isVip)
                                "
                            >
                                <div class="menu-title">
                                    <img :src="item.icon" alt="" />
                                    <span>{{ $t(item.name) }}</span>
                                </div>

                                <div class="menu-list">
                                    <div
                                        class="menu-info"
                                        v-for="(child, childIndex) in item.children"
                                        :key="childIndex"
                                        :class="{
                                            select: routerPath === child.path || preRouterPath === child.path
                                        }"
                                        @click="
                                            $router.push(
                                                child.goHome
                                                    ? `/brandDetail?id=${$store.state.buyersInfo.vendorId}`
                                                    : child.path
                                            )
                                        "
                                    >
                                        {{ $t(child.name) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-aside>
                <el-main>
                    <router-view class="route" />
                </el-main>
            </el-container>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import ManageHeader from '../../components/management/Top.vue';
export default {
    name: 'User',
    data() {
        return {
            menus: [
                {
                    name: 'zhan-ting-guan-li',
                    icon: require('../../assets/pc_info_leftnav_icon_01.png'),
                    isVendor: true,
                    children: [
                        {
                            name: 'zhan-ting-shu-ju',
                            path: '/vendorDatas'
                        },
                        {
                            name: 'chan-pin-guan-li',
                            path: '/vendor/product'
                        },
                        {
                            name: 'fen-lei-guan-li',
                            path: '/vendor/customCategory'
                        },
                        {
                            name: 'an-li-guan-li',
                            path: '/vendor/case'
                        },
                        {
                            name: 'xin-wen-guan-li',
                            path: '/vendor/News'
                        },
                        {
                            name: 'wo-de-zhan-ting',
                            path: '',
                            goHome: true
                        }
                    ]
                },
                {
                    name: 'hui-yuan-zhong-xin',
                    icon: require('../../assets/pc_info_leftnav_icon_02.png'),
                    isVendor: true,
                    isVip: true,
                    children: [
                        {
                            name: 'wo-de-hui-yuan',
                            path: '/vip/vipCenter'
                        }
                        // {
                        //     name: '广告投放',
                        //     path: ''
                        // }
                    ]
                },
                {
                    name: 'xin-xi-guan-li',
                    icon: require('../../assets/pc_info_leftnav_icon_03.png'),
                    children: [
                        {
                            name: 'wo-de-shou-cang',
                            path: '/mineCollection'
                        },
                        // {
                        //     name: '我的积分',
                        //     path: '/minePoint'
                        // },
                        {
                            name: 'wo-de-huo-dong',
                            path: '/mineActivity'
                        },
                        {
                            name: 'liu-lan-ji-lu',
                            path: '/mineReadRecords'
                        },
                        {
                            name: 'chan-pin-xu-qiu-cheng-jiao-dan',
                            path: '/mineOrder'
                        }
                    ]
                },
                {
                    name: 'zhang-hu-she-zhi',
                    icon: require('../../assets/pc_info_leftnav_icon_04.png'),
                    children: [
                        {
                            name: 'qi-ye-zi-liao-wei-hu',
                            path: '/mineVendorInfo'
                        },
                        {
                            name: 'zhang-hu-yu-an-quan',
                            path: '/accountInfo'
                        }
                    ]
                }
            ],
            top: 0
        };
    },
    computed: {
        ...mapState(['userInfo', 'buyersInfo', 'vipInfo']),
        userIcon() {
            return this.buyersInfo ? this.buyersInfo.logo : require('../../assets/img_defaultphoto.png');
        },
        name() {
            return this.buyersInfo.chCompanyName || '';
        },
        introduce() {
            return this.buyersInfo ? this.buyersInfo.enCompanyName || '' : '';
        },
        routerPath() {
            return this.$route.path;
        },
        preRouterPath() {
            return this.$route.meta.prePath;
        },
        member() {
            return this.vipInfo ? this.vipInfo.member : {};
        },
        memberImg() {
            if (this.member.name === '高级会员') {
                return require('../../assets/icon_vip.png');
            } else if (this.member.name === '服务管家') {
                return require('../../assets/icon_guanjia.png');
            } else {
                return null;
            }
        }
    },
    methods: {
        scrollEvent() {
            if (document.documentElement.scrollTop >= 220) {
                this.top = document.documentElement.scrollTop - 220;
            } else {
                this.top = 0;
            }
        },
        goUpgrade() {
            this.$refs.upgrade.dialogVisible = true;
        }
    },
    mounted() {
        window.addEventListener('scroll', this.scrollEvent);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollEvent);
    },
    components: {
        ManageHeader
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';

.main {
    @include center-content(250);
}

.menu {
    // height: calc(100vh - 90px - 40px);
    overflow-y: auto;
    .allmenuInfo {
        background-color: #fff;
        // min-height: 835px;
    }
    .menu-box {
        padding: 10px 0;
        .menu-title {
            display: flex;
            align-items: center;
            padding: 8px 0;
            font-size: 16px;
            color: #a8abb3;
            line-height: 26px;
            padding: 11px 14px;

            img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }

        .menu-list {
            .menu-info {
                padding: 8px 44px;
                font-size: 14px;
                color: #292c33;
                line-height: 24px;
                cursor: pointer;
                &:hover {
                    // background-color: rgba(#000, 0.1);
                    color: #ffa526;
                }

                &.select {
                    color: #ffa526;

                    background: linear-gradient(270deg, rgba(255, 165, 38, 0) 0%, rgba(255, 165, 38, 0.1) 100%);
                    position: relative;
                    &::before {
                        content: '';
                        width: 2px;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        position: absolute;
                        background-color: #ffa526;
                    }
                }
            }
        }
    }
    .menu-box + .menu-box {
        border-top: 1px solid #f2f3f5;
    }
}

.el-container {
    padding: 20px 0;
    // min-height: max(calc(100vh - 90px - 220px), 900px);
    @include max-height(310px, $max: 1200px);
}

.el-main {
    padding: 0;
    margin-left: 20px;
    display: flex;
    .route {
        background-color: #fff;
        flex-grow: 1;
        overflow: hidden;
    }
}

.isEn {
    .menu .menu-box .menu-list {
        .menu-info {
            // padding: 8px 30px;
            font-size: 12px;
        }
    }
}
</style>
