var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('manage-header'),_c('div',{staticClass:"main"},[_c('el-container',{attrs:{"direction":"horizontal"}},[_c('el-aside',{staticStyle:{"overflow":"hidden"},attrs:{"width":"220px"}},[_c('div',{staticClass:"menu scrollbar"},[_c('div',{staticClass:"allmenuInfo"},_vm._l((_vm.menus),function(item,index){return (
                                (!item.isVendor || item.isVendor === _vm.isVendor) &&
                                (!item.isVip || item.isVip === _vm.isVip)
                            )?_c('div',{key:index,staticClass:"menu-box"},[_c('div',{staticClass:"menu-title"},[_c('img',{attrs:{"src":item.icon,"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])]),_c('div',{staticClass:"menu-list"},_vm._l((item.children),function(child,childIndex){return _c('div',{key:childIndex,staticClass:"menu-info",class:{
                                        select: _vm.routerPath === child.path || _vm.preRouterPath === child.path
                                    },on:{"click":function($event){return _vm.$router.push(
                                            child.goHome
                                                ? ("/brandDetail?id=" + (_vm.$store.state.buyersInfo.vendorId))
                                                : child.path
                                        )}}},[_vm._v(" "+_vm._s(_vm.$t(child.name))+" ")])}),0)]):_vm._e()}),0)])]),_c('el-main',[_c('router-view',{staticClass:"route"})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }