<template>
    <div class="top bg" style="backgroundImage: url(http://oss.imttech.cn/case/info_top_bg.jpg)">
        <div class="info">
            <el-image
                :src="userIcon"
                width="120px"
                height="120px"
                v-if="buyersInfo && this.isVendor"
                fit="cover"
            ></el-image>

            <el-avatar :size="120" v-else :src="userIcon"></el-avatar>

            <div class="right">
                <div class="text1" :style="{ paddingTop: isVendor ? '0px' : '28px' }">
                    <span> {{ name }}</span>
                    <img src="../../assets/icon_qiyerenzheng.png" alt="" class="qi" v-if="isVendor" />
                    <img class="vip" :src="memberImg" alt="" v-if="isVip && memberImg" />
                </div>
                <div class="text2" v-if="!isVendor">{{ nickname }}</div>
                <div class="text2" v-else-if="introduce">{{ introduce }}</div>

                <div class="flex1"></div>

                <div class="btn-list">
                    <el-button type="success" size="small" @click="$router.push('/decorateShop')" v-if="isVendor">{{
                        $t('zhuang-xiu-zhan-ting')
                    }}</el-button>
                    <!-- <el-button type="warning" @click="goUpgrade" plain size="small" v-if="isVendor && !isVip">
                        <div class="vip-button">
                            <img class="vip" src="../../assets/icon_vip.png" />
                            <span>{{ $t('sheng-ji-hui-yuan') }}</span>
                        </div>
                    </el-button> -->
                </div>
            </div>
        </div>

        <Upgrade ref="upgrade" />
    </div>
</template>
<script>
import { mapState } from 'vuex';
import Upgrade from '../../views/vip/Upgrade';
export default {
    data() {
        return {};
    },
    computed: {
        ...mapState(['userInfo', 'buyersInfo', 'vipInfo']),
        userIcon() {
            return this.buyersInfo && this.userInfo && this.userInfo.avatar
                ? this.buyersInfo.logo
                    ? this.buyersInfo.logo + '?x-oss-process=image/resize,m_fill,h_240,w_240'
                    : this.userInfo.avatar
                : require('../../assets/img_defaultphoto.png');
        },
        nickname() {
            return this.userInfo ? this.userInfo.nickname || this.userInfo.username : this.$t('wei-deng-lu');
        },
        name() {
            return this.buyersInfo ? this.getName(this.buyersInfo, ['chCompanyName', 'enCompanyName']) : '';
        },
        introduce() {
            return this.buyersInfo ? this.getName(this.buyersInfo, ['chCompanyName', 'enCompanyName']) || '' : '';
        },
        routerPath() {
            return this.$route.path;
        },
        preRouterPath() {
            return this.$route.meta.prePath;
        },
        member() {
            return this.vipInfo ? this.vipInfo.member : {};
        },
        memberImg() {
            if (this.member.name === '高级会员') {
                return require('../../assets/icon_vip.png');
            } else if (this.member.name === '服务管家') {
                return require('../../assets/icon_guanjia.png');
            } else {
                return null;
            }
        }
    },
    methods: {
        goUpgrade() {
            this.$refs.upgrade.dialogVisible = true;
        }
    },
    components: {
        Upgrade
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';

.top {
    height: 220px;
    padding: 50px 0;
    box-sizing: border-box;
}
.info {
    display: flex;
    @include center-content(330);

    .el-image {
        width: 120px;
        height: 120px;
    }
}

.right {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    .text1 {
        display: flex;
        align-items: center;
        font-size: 22px;
        font-weight: bold;
        color: #292c33;
        line-height: 30px;

        .qi {
            width: 21px;
            height: 24px;
            margin-left: 5px;
        }

        .vip {
            width: 46px;
            height: 24px;
            margin-left: 5px;
        }
    }

    .text2 {
        font-size: 14px;
        font-weight: bold;
        color: #818999;
        line-height: 20px;
        margin-top: 6px;
    }
}
.el-button {
    min-width: 110px;
    padding: 0 13px;
    line-height: 32px;
}
.vip-button {
    display: flex;
    align-items: center;
    line-height: 20px;
    height: 32px;

    .vip {
        width: 34px;
        height: 18px;
    }
}
.el-button--success {
    background-color: #1dd2ac;
    border-color: #1dd2ac;

    &:hover {
        background-color: mix($--color-black, #1dd2ac, $--button-active-shade-percent);
        border-color: mix($--color-black, #1dd2ac, $--button-active-shade-percent);
    }
}

.btn-list {
    display: flex;
    align-items: center;
}
</style>
