<template>
    <el-dialog :visible.sync="dialogVisible" width="940px" :title="$t('sheng-ji-hui-yuan')" center append-to-body>
        <div class="viplist">
            <div class="vip-info" v-for="(item, index) in list" :key="index">
                <div class="top">
                    <div class="name">{{ item.name }}</div>
                    <div class="sub" v-html="getHtml(item)"></div>
                </div>

                <div class="info">
                    <div v-for="(v, vI) in item.values" :key="vI">{{ v }}</div>
                    <div>...</div>
                </div>

                <div class="price">
                    {{ item.price > 0 ? item.price : $t('mian-fei') }} <span v-if="item.price > 0">{{$t('yuan-nian')}}</span>
                </div>

                <el-button v-if="index === useType" type="info" plain size="small" @click="dialogVisible = false"
                    >{{$t('ji-xu-shi-yong')}}</el-button
                >
                <el-button
                    v-else-if="index === 1"
                    type="warning"
                    size="small"
                    @click="$router.push('/vip/submit?id=' + item.id)"
                    >{{$t('xian-shi-mian-fei')}}</el-button
                >
                <el-button v-else type="info" size="small" disabled>{{$t('jing-qing-qi-dai')}}</el-button>
            </div>

            <el-link type="warning">{{$t('dian-ji-cha-kan-bu-tong-deng-ji-xiang-xi-hui-yuan-quan-xian')}}</el-link>
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'edit',
    data() {
        return {
            dialogVisible: false,
            useType: 0,
            list: [
                {
                    id: 0,
                    name: '普通会员',
                    values: ['展厅基础装修', '产品上传/维护', '用户消息查询/回复'],
                    price: 0
                }
            ],
            extraData: [
                {
                    img: require('../../assets/icon_vip.png'),
                    values: [
                        '展厅高级装修/自定义排版',
                        '展厅或产品可传视频',
                        '产品上传/维护',
                        '用户消息查询/回复',
                        '企业及产品排序优化',
                        '店铺访问数据查询',
                        '相关数据报告'
                    ]
                },
                {
                    img: require('../../assets/icon_guanjia.png'),
                    values: [
                        '展厅高级装修/自定义排版',
                        '展厅或产品可传视频',
                        '产品上传/维护',
                        '平台代理维护产品信息',
                        '网站信息内容翻译服务',
                        '平台协助用户对消息查询/回复',
                        '企业及产品排序优化',
                        '店铺访问数据查询',
                        '相关数据报告'
                    ]
                }
            ]
        };
    },
    mounted() {
        this.$http.post('/member/all', {}, { body: 'json' }).then(res => {
            const list = res.content
                .sort((a, b) => {
                    return a.price - b.price;
                })
                .map((item, index) => {
                    return {
                        ...item,
                        ...this.extraData[index]
                    };
                });
            this.list = [...this.list, ...list];
        });
    },
    methods: {
        submit() {
            const form = { ...this.form };
            if (!this.form.id) {
                form.vendorInfoId = this.vendorInfo.id;
            }
            this.$http
                .post('/customCategory/save', form, { body: 'json' })
                .then(res => {
                    this.$message.success('提交成功');
                    this.dialogVisible = false;
                    this.$emit('getAll');
                })
                .catch(e => {
                    this.$message.warning(e.error);
                });
        },
        show(data) {
            this.form = { ...data };
            this.dialogVisible = true;
        },
        onDelete() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/productParameter/del/${this.form.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error(e.error);
                    }
                });
        },
        getHtml(info) {
            if (info.id === 0) {
                return '<div class="text1">当前等级</div>';
            } else if (info.img) {
                return '<img src="' + info.img + '" />';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.dialog-footer {
    .el-button {
        height: 36px;
        min-width: 120px;

        &.el-button--warning {
            min-width: 160px;
        }
    }
    .el-button + .el-button {
        margin-left: 20px;
    }
}

$colors: [ #8aa3c9, #e1be64, #c66767 ];

.viplist {
    display: flex;
    justify-content: center;
    padding: 45px 0;
    position: relative;

    .el-link {
        position: absolute;
        left: 80px;
        bottom: -10px;
    }
}
.vip-info {
    width: 240px;
    height: 500px;
    background: #ffffff;
    border: 1px solid #e6e9f0;
    display: flex;
    flex-direction: column;
    margin: 0 15px;
    transition: all ease-in-out 0.3s;
    &:hover {
        box-shadow: 0px 12px 18px 0px rgba(15, 38, 77, 0.12);
        transform: translateY(-10px);
    }
    .top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 110px;
        width: 100%;

        .name {
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            line-height: 25px;
        }
    }

    .info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #878d99;
        line-height: 24px;
    }

    .price {
        font-size: 22px;
        font-weight: bold;
        color: #292c33;
        line-height: 30px;
        display: flex;
        justify-content: center;
        span {
            font-size: 14px;
        }
    }

    .el-button {
        display: block;
        margin: 18px 40px 30px;
        border-width: 0px;
        height: 40px;
    }

    @each $color in $colors {
        $index: index($colors, $color);
        &:nth-child(6n + #{$index}) {
            .top {
                background-color: $color;
            }
        }
    }
}
</style>

<style lang="scss">
.vip-info {
    .sub {
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
        margin-top: 12px;
        img {
            height: 24px;
            display: block;
        }
    }
}
</style>
